<main>
  <header class="brand-name">
    <ngbd-memberForm
      #addUserForm
      (membersUpdated)="membersTable.updateTable()"
    ></ngbd-memberForm>
    <ngbd-cardModal
      #cardModal
      (cardStateChanged)="membersTable.updateTable()"
    ></ngbd-cardModal>
  </header>
  <section class="content">
    <h2>Członkowie</h2>
    <button (click)="addUserForm.openForm()">Dodaj członka</button>
  </section>
  <ngbd-members-table
    #membersTable
    (editRowClicked)="addUserForm.openFormEdit($event)"
    (cardsClicked)="cardModal.openForm($event)"
  ></ngbd-members-table>
</main>
`,
