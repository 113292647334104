import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ValidateCard } from '../model/validateCard';

@Injectable({
  providedIn: 'any',
})
export class validateCardService {
  private url: string = `${environment.apiUrl}/validate`;

  constructor(private http: HttpClient) {}

  validateCard(id: string) {
    return this.http.get<{
      message: string;
      payload?: ValidateCard;
    }>(`${this.url}?cardId=${id}`);
  }
}
