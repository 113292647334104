/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { DecimalPipe } from '@angular/common';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from './sortableAdmins.directive';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { getAuthorizationHeaders } from '../utils/utils';
import { Admin, AdminPayload } from '../model/admin';

interface SearchResult {
  admins: Admin[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}

const compare = (
  v1: string | number | Date,
  v2: string | number | Date,
): number => {
  if (!v1 && !v2) return 0;

  if (!v1 && v2) return -1;

  if (v1 && !v2) return 1;

  if (!v1 || !v2) return -1;

  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
};

function sort(admins: Admin[], column: SortColumn, direction: string): Admin[] {
  if (direction === '' || column === '') {
    return admins;
  } else {
    return [...admins].sort((a, b) => {
      if (!a[column] && !b[column]) return 0;

      if (!a[column]) return 1;

      if (!b[column]) return -1;

      if (!a[column] || !b[column]) return 0;

      const res = compare(a[column]!, b[column]!);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(admin: Admin, term: string) {
  return (
    admin.firstname.toLowerCase().includes(term.toLowerCase()) ||
    admin.lastName.toLowerCase().includes(term.toLowerCase()) ||
    admin.username.toLowerCase().includes(term.toLowerCase()) ||
    admin.email.toLowerCase().includes(term.toLowerCase())
  );
}

@Injectable({ providedIn: 'any' })
export class AdminsService {
  private admins: Admin[] = [];

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _admins = new BehaviorSubject<Admin[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
  };

  constructor(private httpClient: HttpClient) {
    this.updateAdmins();

    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false)),
      )
      .subscribe((result) => {
        this._admins.next(result.admins);
        this._total$.next(result.total);
      });

    this._search$.next();
  }

  get members$() {
    return this._admins.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } =
      this._state;

    // 1. sort
    let admins = sort(this.admins, sortColumn, sortDirection);

    // 2. filter
    admins = admins.filter((member) => matches(member, searchTerm));
    const total = admins.length;

    // 3. paginate
    admins = admins.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize,
    );
    return of({ admins: admins, total });
  }

  updateAdmins() {
    this.getAdminsFromBackEnd().subscribe((value) => {
      this.admins = value.payload.map<Admin>((pl) => {
        return {
          firstname: pl.firstname,
          lastName: pl.lastName,
          username: pl.username,
          id: pl.id!,
          email: pl.email,
        };
      });
      this._search$.next();
    });
  }

  private getAdminsFromBackEnd(): Observable<{
    message: string;
    payload: AdminPayload[];
  }> {
    return this.httpClient.get<{ message: string; payload: AdminPayload[] }>(
      `${environment.apiUrl}/admin`,
      { headers: getAuthorizationHeaders() },
    );
  }

  public addAdmin(member: AdminPayload): Observable<{
    message: string;
    status: string;
  }> {
    return this.httpClient.post<{
      status: string;
      message: string;
      payload: AdminPayload[];
    }>(`${environment.apiUrl}/admin`, member, {
      headers: getAuthorizationHeaders(),
    });
  }

  public editAdmin(admin: AdminPayload): Observable<{
    message: string;
    status: string;
  }> {
    return this.httpClient.put<{
      status: string;
      message: string;
      payload: AdminPayload[];
    }>(`${environment.apiUrl}/admin`, admin, {
      headers: getAuthorizationHeaders(),
    });
  }

  public triggerResetPassword(admin: Admin): Observable<{
    message: string;
    status: string;
  }> {
    return this.httpClient.post<{
      status: string;
      message: string;
      payload: AdminPayload[];
    }>(
      `${environment.apiUrl}/admin/triggerPasswordReset`,
      { id: admin.id },
      {
        headers: getAuthorizationHeaders(),
      },
    );
  }

  public deleteAdmin(id: string): Observable<{
    message: string;
    status: string;
  }> {
    return this.httpClient.delete<{
      status: string;
      message: string;
    }>(`${environment.apiUrl}/admin/${id}`, {
      headers: getAuthorizationHeaders(),
    });
  }
}
