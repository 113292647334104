import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { MembersTable } from '../membersTable/membersTable';
import { memberForm } from '../memberForm/memberForm';
import { cardsModal } from '../cardsModal/cardsModal';
import { CommonModule } from '@angular/common';
import { BaseAuthenticatedPage } from '../page/baseAuthenticatedPage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-members-page',
  standalone: true,
  imports: [MembersTable, memberForm, cardsModal, CommonModule],
  templateUrl: './membersPage.component.html',
  styleUrl: './membersPage.component.css',
})
export class MembersPageComponent extends BaseAuthenticatedPage {
  title = 'BSP - Legitymacje';

  constructor(router: Router) {
    super(router);
  }
}
