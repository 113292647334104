import { CommonModule, DecimalPipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Output,
  TemplateRef,
  viewChild,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  ModalDismissReasons,
  NgbDate,
  NgbDatepickerModule,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';

import { Member, MemberPayload } from '../../model/member';
import { MembersService } from '../../services/members.service';

enum mode {
  add,
  edit,
}

@Component({
  selector: 'ngbd-memberForm',
  standalone: true,
  imports: [
    NgbDatepickerModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DecimalPipe,
  ],
  templateUrl: './memberForm.html',
  styleUrls: ['./memberForm.css'],
  providers: [MembersService, DecimalPipe],
})
export class memberForm {
  @Output() membersUpdated = new EventEmitter();

  private modalService = inject(NgbModal);
  private mode = mode.add;
  title = 'Dodaj członka';
  private today = new Date();
  defaultDateOfBirth: NgbDate = new NgbDate(
    this.today.getFullYear() - 20,
    this.today.getMonth() + 1,
    this.today.getDate(),
  );
  defaultAddedDate: NgbDate = new NgbDate(
    this.today.getFullYear(),
    this.today.getMonth() + 1,
    this.today.getDate(),
  );
  private modal: NgbModalRef | null = null;

  addMemberForm = new FormGroup({
    id: new FormControl(),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    phone: new FormControl(''),
    dateOfBirth: new FormControl<NgbDate | undefined>(undefined, [
      Validators.required,
    ]),
    addedDate: new FormControl<NgbDate | undefined>(undefined),
    streetAndNumber: new FormControl('', [Validators.required]),
    postalCode: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
  });

  showValidationMessages = {
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    dateOfBirth: false,
    streetAndNumber: false,
    postalCodeAndCity: false,
    country: false,
  };

  @ViewChild('openFormButton') openFormButton!: ElementRef;

  closeResult = '';

  constructor(public service: MembersService) {}

  public openForm() {
    this.title = 'Dodaj członka';
    this.mode = mode.add;
    this.openFormButton.nativeElement.click();
  }

  public openFormEdit(member: Member) {
    this.showValidationMessages = {
      firstName: false,
      lastName: false,
      email: false,
      phone: false,
      dateOfBirth: false,
      streetAndNumber: false,
      postalCodeAndCity: false,
      country: false,
    };
    this.title = 'Edytuj członka';
    this.mode = mode.edit;

    const addedDate: NgbDate | null = member.addedDate
      ? new NgbDate(
          member.addedDate.getFullYear(),
          member.addedDate.getMonth() + 1,
          member.addedDate.getDate(),
        )
      : null;
    const birthdayDate: NgbDate | null = member.birthDate
      ? new NgbDate(
          member.birthDate.getFullYear(),
          member.birthDate.getMonth() + 1,
          member.birthDate.getDate(),
        )
      : null;

    this.openFormButton.nativeElement.click();

    this.addMemberForm.setValue({
      id: member.id,
      firstName: member.firstName,
      lastName: member.lastName,
      email: member.email,
      phone: member.phoneNumber || null,
      addedDate: addedDate,
      dateOfBirth: birthdayDate,
      streetAndNumber: member.streetAndNumber,
      postalCode: member.postalCode,
      city: member.city,
      country: member.country,
    });
  }

  public open(content: TemplateRef<any>) {
    this.showValidationMessages = {
      firstName: false,
      lastName: false,
      email: false,
      phone: false,
      dateOfBirth: false,
      streetAndNumber: false,
      postalCodeAndCity: false,
      country: false,
    };
    this.addMemberForm.setValue({
      id: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      addedDate: this.defaultAddedDate,
      dateOfBirth: null,
      streetAndNumber: null,
      postalCode: null,
      city: null,
      country: 'Polska',
    });

    this.modal = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });

    this.modal.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  submit() {
    this.showValidationMessages.firstName =
      !this.addMemberForm.controls.firstName.valid;
    this.showValidationMessages.lastName =
      !this.addMemberForm.controls.lastName.valid;
    this.showValidationMessages.email =
      !this.addMemberForm.controls.email.valid;
    this.showValidationMessages.phone =
      !this.addMemberForm.controls.phone.valid;
    this.showValidationMessages.dateOfBirth =
      !this.addMemberForm.controls.dateOfBirth.valid;
    this.showValidationMessages.streetAndNumber =
      !this.addMemberForm.controls.streetAndNumber.valid;
    this.showValidationMessages.postalCodeAndCity =
      !this.addMemberForm.controls.postalCode.valid ||
      !this.addMemberForm.controls.city.valid;
    this.showValidationMessages.country =
      !this.addMemberForm.controls.country.valid;

    if (!this.addMemberForm.valid) return;

    if (this.mode === mode.add) {
      const member = this.readMemberForm();

      this.service.addMember(member).subscribe((value) => {
        if (value.status === 'OK') {
          window.alert(`Członek został dodany`);
          this.membersUpdated.emit();
          this.modal?.close();
        } else window.alert(`Member create failed: ${value.message}`);
      });
    }

    if (this.mode === mode.edit) {
      const member = this.readMemberForm();

      this.service.editMember(member).subscribe((value) => {
        if (value.status === 'OK') {
          this.membersUpdated.emit();
          window.alert(`Członek zaktualizowny`);
          this.modal?.close();
        } else window.alert(`Member update failed: ${value.message}`);
      });
    }
  }

  private readMemberForm(): MemberPayload {
    const addedDate = this.addMemberForm.value.addedDate;
    const dateOfBirth = this.addMemberForm.value.dateOfBirth;
    const member: MemberPayload = {
      id: this.addMemberForm.value.id ?? '',
      firstName: this.addMemberForm.value.firstName!,
      lastName: this.addMemberForm.value.lastName!,
      email: this.addMemberForm.value.email!,
      phoneNumber: this.addMemberForm.value.phone!,
      addedDate: addedDate
        ? `${addedDate!.year}-${addedDate!.month}-${addedDate!.day}`
        : undefined,
      birthDate: dateOfBirth
        ? `${dateOfBirth!.year}-${dateOfBirth!.month}-${dateOfBirth!.day}`
        : undefined,
      streetAndNumber: this.addMemberForm.value.streetAndNumber!,
      postalCode: this.addMemberForm.value.postalCode!,
      city: this.addMemberForm.value.city!,
      country: this.addMemberForm.value.country!,
    };
    return member;
  }

  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }
}
