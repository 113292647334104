import { AsyncPipe, DecimalPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  NgbdSortableHeader,
  SortEvent,
} from '../../services/sortableAdmins.directive';
import { FormsModule } from '@angular/forms';
import { NgbHighlight, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { Admin } from '../../model/admin';
import { AdminsService } from '../../services/admins.service';

@Component({
  selector: 'ngbd-admins-table',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    AsyncPipe,
    NgbHighlight,
    NgbdSortableHeader,
    NgbPaginationModule,
  ],
  templateUrl: './adminsTable.html',
  styleUrl: './adminsTable.css',
  providers: [AdminsService, DecimalPipe],
})
export class AdminsTable {
  admins$: Observable<Admin[]>;
  total$: Observable<number>;

  @ViewChildren(NgbdSortableHeader)
  headers!: QueryList<NgbdSortableHeader>;

  sortColumn: string = '';
  sortDir: string = '';

  @Output() editRowClicked = new EventEmitter<Admin>();

  constructor(public service: AdminsService) {
    this.admins$ = service.members$;
    this.total$ = service.total$;
  }

  updateTable() {
    this.service.updateAdmins();
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

    this.sortColumn = column.toString();
    this.sortDir = direction.toString();
  }

  trigerResetPassword(admin: Admin) {
    if (
      window.confirm(
        `Czy chesz wysłać link do ustawiania nowego hasła na email: ${admin.email}`,
      )
    ) {
      this.service.triggerResetPassword(admin).subscribe({
        next: (value) => {
          if (value.status === 'OK') {
            window.alert('Wysłano email pozwalający na ustawienie hasła!');
          }
        },
      });
    }
  }

  deleteAdmin(admin: Admin) {
    const confirmed = window.confirm(
      'Czy napewno chcesz usunąć admina? Ta operacja jest nieodwracalna!',
    );

    if (confirmed) {
      const sub = this.service.deleteAdmin(admin.id).subscribe({
        next: () => {
          this.service.updateAdmins();
          sub.unsubscribe();
        },
        error: (error) => {
          window.alert(`Wystąpił problem ${JSON.stringify(error)}`);
        },
      });
    }
  }
}
