import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'any',
})
export class PasswordService {
  private url: string = `${environment.apiUrl}/resetPassword`;

  constructor(private http: HttpClient) {}

  resetPassword(token: string, password: string, repeatPassword: string) {
    const data = {
      id: token,
      password,
      repeatPassword,
    };

    return this.http.post<{ status: string; message: string }>(
      this.url + '/changePassword',
      data,
    );
  }

  checkLinkValidity(
    token: string,
  ): Observable<{ status: string; message: string }> {
    const data = {
      id: token,
    };

    return this.http.post<{ status: string; message: string }>(
      this.url + '/checkToken',
      data,
    );
  }
}
