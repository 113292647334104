<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    Legitymacje - {{member.firstName}} {{member.lastName}}
  </div>

  <form [formGroup]="addCardForm">
    <div style="display: flex; width: 300px; margin: auto">
      <div style="margin: auto">Dodaj&nbsp;Legitymację:&nbsp;</div>
      <input
        id="year"
        formControlName="year"
        class="form-control"
        type="number"
        min="2005"
        max="2055"
        [value]="2024"
      />
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="submitNewCard()"
      >
        Dodaj
      </button>
    </div>
  </form>
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col" sortable="firstName">Rok</th>
        <th scope="col" sortable="lastName">Aktywna</th>
      </tr>
    </thead>
    <tbody>
      @for (card of cards | slice: (page-1) * pageSize : page * pageSize; track
      card.id) {
      <tr>
        <td>
          <ngb-highlight [result]="card.year.toString()" [term]="''" />
        </td>
        <td>
          <ngb-highlight
            [result]="card.isActive ? 'Aktywna':'Nieaktywna'"
            [term]="''"
          />
        </td>

        <td>
          <button (click)="deleteCardClicked(card)">Usuń</button>
          <button
            #deactivateButton
            *ngIf="card.isActive"
            (click)="deactivate(card,deactivateButton)"
          >
            Unieważnij
          </button>
          <button
            #activateButton
            *ngIf="!card.isActive"
            (click)="activate(card,activateButton)"
          >
            Aktywuj
          </button>
          <button
            #loadingButton
            type="button"
            (click)="downloadCard(card.id,loadingButton)"
          >
            Pobierz
          </button>
        </td>
      </tr>
      } @empty {
      <tr>
        <td colspan="2" style="text-align: center">{{noCardMessage}}</td>
      </tr>
      }
    </tbody>
    <div>
      <ngb-pagination
        [(page)]="page"
        [pageSize]="pageSize"
        [collectionSize]="cards.length"
      />
    </div>
  </table>
</ng-template>

<button #openFormButton hidden (click)="open(content)">Dodaj</button>
