<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="addAdminForm">
      <input
        id="id"
        formControlName="id"
        class="form-control"
        placeholder=""
        name="dp"
        hidden
      />
      <div class="mb-3">
        <label for="firstName">Imię</label>
        <div class="input-group">
          <input
            id="firstName"
            formControlName="firstName"
            class="form-control"
            placeholder="Imię"
            name="dp"
          />
        </div>
        <div
          id="firstNameValError"
          [hidden]="!showValidationMessages.firstName"
          class="validationMessage"
        >
          To pole jest wymagane!
        </div>
      </div>
      <div class="mb-3">
        <label for="lastName">Nazwisko</label>
        <div class="input-group">
          <input
            id="lastName"
            formControlName="lastName"
            class="form-control"
            placeholder="Nazwisko"
            name="dp"
          />
        </div>
        <div
          [hidden]="!showValidationMessages.lastName"
          class="validationMessage"
        >
          To pole jest wymagane!
        </div>
      </div>
      <div class="mb-3">
        <label for="email">Email</label>
        <div class="input-group">
          <input
            id="email"
            formControlName="email"
            class="form-control"
            placeholder="email"
            name="dp"
            email
          />
        </div>
        <div [hidden]="!showValidationMessages.email" class="validationMessage">
          Poprawny email jest wymagany!
        </div>
      </div>
      <div class="mb-3">
        <label for="username">Nazwa użytkownika</label>
        <div class="input-group">
          <input
            id="username"
            formControlName="username"
            class="form-control"
            placeholder="Nazwa użytkownika"
            name="dp"
          />
        </div>
        <div
          class="validationMessage"
          [hidden]="!showValidationMessages.username"
        >
          To pole jest wymagane!
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="submit();">
      Zapisz
    </button>
  </div>
</ng-template>

<button #openFormButton hidden (click)="open(content)">Dodaj</button>
