<div class="page">
  <div class="loginContainer">
    <div [hidden]="qrMode">
      <div>
        <label>Użytkownik:</label>
        <input #username class="loginElement" />
      </div>
      <br />
      <div>
        <label>Hasło:</label>
        <input
          #password
          class="loginElement"
          type="password"
          (keyup)="keyInPasswordPage($event.key, submitBtn)"
        />
      </div>
      <br />
      <div id="wrongPasswordMessage" class="message" [hidden]="!wrongPassword">
        Zła nazwa uzytkownika lub hasło! <br />
      </div>
      <button
        #submitBtn
        class="loginElement"
        (click)="onSubmit(username.value, password.value, submitBtn)"
      >
        Zaloguj</button
      ><br />
    </div>
  </div>
</div>
