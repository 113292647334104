<div class="page">
  <div [hidden]="!loading">Ładowanie...</div>
  <div [hidden]="loading">
    <div class="inactiveCard" [hidden]="cardIsActive">
      <div class="center">
        Legitymacja jest nieaktywna w roku {{ currentYear }}
      </div>
    </div>
    <div class="activeCard" [hidden]="!cardIsActive">
      <div class="center">
        Legitymaja jest aktywna!<br />
        <p>
          Imie: {{ firstName }} <br />Nazwisko: {{ lastName }} <br />Data
          urodzin:
          {{ birthDate }}
        </p>
      </div>
    </div>
  </div>
</div>
