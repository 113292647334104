import { Component } from '@angular/core';

import { cardsModal } from '../cardsModal/cardsModal';
import { CommonModule } from '@angular/common';
import { BaseAuthenticatedPage } from '../page/baseAuthenticatedPage';
import { Router } from '@angular/router';
import { AdminsTable } from '../adminsTable/adminsTable';
import { AdminForm } from '../adminForm/adminForm';

@Component({
  selector: 'app-admin-page',
  standalone: true,
  imports: [AdminsTable, AdminForm, cardsModal, CommonModule],
  templateUrl: './adminsPage.component.html',
  styleUrl: './adminsPage.component.css',
})
export class adminsPageComponent extends BaseAuthenticatedPage {
  title = 'BSP - Legitymacje';
  loggedIn = false;

  onLoggedIn() {
    this.loggedIn = true;
  }

  constructor(router: Router) {
    super(router);
  }
}
