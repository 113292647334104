import { AsyncPipe, DecimalPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Observable } from 'rxjs';

import { Member } from '../../model/member';
import { MembersService } from '../../services/members.service';
import {
  NgbdSortableHeader,
  SortEvent,
} from '../../services/sortable.directive';
import { FormsModule } from '@angular/forms';
import { NgbHighlight, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-members-table',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    AsyncPipe,
    NgbHighlight,
    NgbdSortableHeader,
    NgbPaginationModule,
  ],
  templateUrl: './membersTable.html',
  styleUrl: './membersTable.css',
  providers: [MembersService, DecimalPipe],
})
export class MembersTable {
  members$: Observable<Member[]>;
  total$: Observable<number>;
  sortColumn: string = '';
  sortDir: string = '';

  @ViewChildren(NgbdSortableHeader)
  headers!: QueryList<NgbdSortableHeader>;

  @Output() editRowClicked = new EventEmitter<Member>();
  @Output() cardsClicked = new EventEmitter<Member>();

  constructor(public service: MembersService) {
    this.members$ = service.members$;
    this.total$ = service.total$;
  }

  updateTable() {
    this.service.updateMembers();
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

    this.sortColumn = column.toString();
    this.sortDir = direction.toString();
    console.log(this.sortDir);
  }

  onEditClick(member: Member) {
    this.editRowClicked.emit(member);
  }

  onCardsClick(member: Member) {
    this.cardsClicked.emit(member);
  }

  deleteMember(member: Member) {
    const confirmed = window.confirm(
      'Czy napewno chcesz usunąć członka oraz wszystkie jego legitymacje? Ta operacja jest nieodwracalna!',
    );

    if (confirmed) {
      const sub = this.service.deleteMember(member.id).subscribe({
        next: () => {
          this.service.updateMembers();
          sub.unsubscribe();
        },
        error: (error) => {
          window.alert(`Wystąpił problem ${JSON.stringify(error)}`);
        },
      });
    }
  }

  transformAdress(member: Member) {
    return `${member.streetAndNumber}, ${member.postalCode} ${member.city}, ${member.country}`;
  }
}
