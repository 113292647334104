import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  ModalDismissReasons,
  NgbDatepickerModule,
  NgbHighlight,
  NgbModal,
  NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { CardsService } from '../../services/cards.service';
import { Observable } from 'rxjs';
import { Card } from '../../model/card';
import { Member } from '../../model/member';
import { ButtonAnimation } from '../../utils/buttonAnimation';

@Component({
  selector: 'ngbd-cardModal',
  standalone: true,
  imports: [
    NgbDatepickerModule,
    NgbPaginationModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbHighlight,
  ],
  providers: [CardsService],
  templateUrl: './cardsModal.html',
  styleUrls: ['./cardsModal.css'],
})
export class cardsModal {
  @Output() cardStateChanged = new EventEmitter();

  private modalService = inject(NgbModal);
  member: Member = {
    memberNumber: 0,
    firstName: '',
    lastName: '',
    birthDate: new Date(),
    addedDate: new Date(),
    id: '',
    email: '',
    phoneNumber: '',
    streetAndNumber: '',
    postalCode: '',
    city: '',
    country: '',
  };
  title = 'Cards';
  today = new Date();
  cards: Card[] = [];
  page = 1;
  pageSize = 5;
  noCardMessage = 'Nie znaleziono legitymacji!';

  addCardForm = new FormGroup({
    year: new FormControl<number>(new Date().getFullYear()),
  });

  constructor(public service: CardsService) {}

  @ViewChild('openFormButton') openFormButton!: ElementRef;

  closeResult = '';

  public openForm(member: Member) {
    this.cards = [];
    this.member = member;
    this.title = 'Legitymacje';
    this.openFormButton.nativeElement.click();
    this.noCardMessage = 'Ładowanie...';
    this.service.getCards(this.member.id).subscribe((result) => {
      this.cards = result.payload;
      this.noCardMessage = 'Nie znaleziono legitymacji!';
    });
  }

  public open(content: TemplateRef<any>) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
  }

  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }

  submitNewCard() {
    console.log(this.addCardForm.value.year);
    if (this.addCardForm.value.year) {
      this.service
        .addCard(this.member.id, this.addCardForm.value.year)
        .subscribe((value) => {
          if (value.status === 'OK') {
            this.service.getCards(this.member.id).subscribe((result) => {
              this.cards = result.payload;
            });
          } else
            window.alert(
              `Nie udało się utworzyć legitymacji: ${value.message}`,
            );
        });
    }
  }

  downloadCard(cardId: string, button: Element) {
    const animation = new ButtonAnimation(button);
    animation.startAnimation();
    this.service.downloadCard(cardId).subscribe(
      (response) => {
        const blob = new Blob([response], { type: response.type });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = `card-${cardId}.png`; // Nazwa pliku do zapisania
        link.click();

        window.URL.revokeObjectURL(url);

        animation.stopAnimation();
      },
      (error) => {
        console.error('Error downloading file:', error);
        animation.stopAnimation();
      },
    );
  }

  deleteCardClicked(card: Card) {
    const confirm = window.confirm(
      `Czy napewno chcesz usunąć legitymacje na rok ${card.year}`,
    );

    if (confirm)
      this.service.deleteCard(card.id).subscribe((value) => {
        if (value.status === 'OK') {
          this.service.getCards(this.member.id).subscribe((result) => {
            this.cards = result.payload;
          });
          this.cardStateChanged.emit();
        } else
          window.alert(`Nie udało się usunąć legitymacji: ${value.message}`);
      });
  }

  activate(card: Card, button: Element) {
    const confirm = window.confirm(
      `Czy napewno chcesz aktywować legitymację na rok ${card.year}\n Ta akcja spowoduje wysłanie legitymacji członkowi!`,
    );

    if (confirm) {
      const animation = new ButtonAnimation(button);
      animation.startAnimation();
      this.service.activateCard(card.id).subscribe((value) => {
        if (value.status === 'OK') {
          this.service.getCards(this.member.id).subscribe((result) => {
            this.cards = result.payload;
          });
          this.cardStateChanged.emit();
        } else window.alert(`Nie udało się aktywować: ${value.message}`);
        animation.stopAnimation();
      });
    }
  }

  deactivate(card: Card, button: Element) {
    const confirm = window.confirm(
      `Czy napewno chesz unieważnić kartę na rok ${card.year}?`,
    );

    const animation = new ButtonAnimation(button);

    if (confirm) {
      animation.startAnimation();
      this.service.deactivateCard(card.id).subscribe((value) => {
        if (value.status === 'OK') {
          this.service.getCards(this.member.id).subscribe((result) => {
            this.cards = result.payload;
          });
          this.cardStateChanged.emit();
        } else window.alert(`Nie udało się unieważnić karty: ${value.message}`);
        animation.stopAnimation();
      });
    }
  }
}
