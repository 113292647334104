<div class="page">
  <div class="resetPasswordContainer">
    <div class="message" [hidden]="!linkExpired">
      Link do zmiany hasła wygasł.
    </div>
  </div>
  <div class="resetPasswordContainer" [hidden]="linkExpired">
    <div>
      <form [formGroup]="resetPasswordForm">
        <div>
          <label>Hasło:</label>
          <input
            formControlName="password"
            #password
            type="password"
            class="loginElement"
          />
        </div>
        <br />
        <div>
          <label>Powtórz hasło:</label>
          <input
            formControlName="repeatPassword"
            #passwordRepeat
            class="loginElement"
            type="password"
            (keyup)="keyInPasswordPage($event.key, submitBtn)"
          />
        </div>
        <br />
        <div
          id="wrongPasswordMessage"
          class="message"
          [hidden]="!wrongPassword"
        >
          Hasło niepoprawne!<br />
          Hasło powinno miec co najmiej 8 znaków <br />
        </div>
        <div
          id="differentPasswords"
          class="message"
          [hidden]="!differentPasswords"
        >
          Hasła się nie zgadzają! <br />
        </div>
        <button #submitBtn class="loginElement" (click)="onSubmit(submitBtn)">
          Ustaw Hasło</button
        ><br />
      </form>
    </div>
  </div>
</div>
