import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { RoutesPaths } from '../../app/app-routing.module';
import { LoggedInStateValidator } from '../../utils/loggedInStateValidator';

@Component({
  templateUrl: 'baseAuthenticatedPage.html',
})
export abstract class BaseAuthenticatedPage implements OnInit {
  authService: AuthService = inject(AuthService);
  loggedInStateValidator: LoggedInStateValidator;

  constructor(protected router: Router) {
    this.loggedInStateValidator = LoggedInStateValidator.getInstance(
      this.authService,
    );
  }
  ngOnInit() {
    setInterval(() => this.checkToken(), 5000);
  }

  checkToken() {
    if (!this.loggedInStateValidator.isLoggedIn) {
      this.router.navigate(['/', RoutesPaths.login]);
    }
  }
}
