import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'any',
})
export class AuthService {
  private url: string = `${environment.apiUrl}/auth`;

  constructor(private http: HttpClient) {}

  login(username: string, password: string, token: string) {
    const data = {
      username,
      password,
      token,
    };

    return this.http.post<{ jwt: string }>(this.url, data);
  }

  validateToken(token: string) {
    let headers: HttpHeaders = new HttpHeaders();

    headers = headers.append('Authorization', `Bearer ${token}`);
    console.log(headers);

    return this.http.post(`${this.url}/validate`, null, { headers: headers });
  }
}
