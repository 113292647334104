import { AuthService } from '../services/auth.service';

export class LoggedInStateValidator {
  private static instance: LoggedInStateValidator;
  public isLoggedIn: boolean = false;

  public static getInstance(authService: AuthService) {
    if (!LoggedInStateValidator.instance)
      LoggedInStateValidator.instance = new LoggedInStateValidator(authService);

    return LoggedInStateValidator.instance;
  }

  private constructor(private authService: AuthService) {
    setInterval(() => {
      this.validateLoggedInState().then((result) => {
        this.isLoggedIn = result;
      });
    }, 5000);
  }

  private async validateLoggedInState(): Promise<boolean> {
    const token = window.localStorage.getItem('token');
    if (token) {
      const result = new Promise<boolean>((resolve, reject) => {
        this.authService.validateToken(token).subscribe({
          error: (error) => {
            if (error.status) {
              if (error.status === 401) {
                window.localStorage.removeItem('token');
                resolve(false);
              }
            }
            reject('Wrong Error Code');
          },
          next: () => {
            resolve(true);
          },
          complete: () => {
            reject('Wrong Error Code');
          },
        });
      });

      return result;
    } else {
      console.log(`TokenNotFound`);
      return false;
    }
  }

  public async getLoggedInStateNow() {
    const loggedIn = this.validateLoggedInState();

    loggedIn.then(
      (result) => {
        this.isLoggedIn = result;
      },
      (error) => {},
    );

    return loggedIn;
  }
}
