/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { Injectable, PipeTransform } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { Member, MemberPayload } from '../model/member';

import { DecimalPipe } from '@angular/common';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from './sortable.directive';
import { HttpClient } from '@angular/common/http';
import { environment } from './../environments/environment';
import { getAuthorizationHeaders } from '../utils/utils';

interface SearchResult {
  members: Member[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}

const compare = (
  v1: string | number | Date | boolean,
  v2: string | number | Date | boolean,
): number => {
  v1 = typeof v1 === 'string' ? v1.toLowerCase() : v1;
  v2 = typeof v2 === 'string' ? v2.toLowerCase() : v2;

  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
};

function sort(
  countries: Member[],
  column: SortColumn,
  direction: string,
): Member[] {
  if (direction === '' || column === '') {
    return countries;
  } else {
    return [...countries].sort((a, b) => {
      const aValueToSort = a[column];
      const bValueToSort = b[column];

      if (aValueToSort === undefined || bValueToSort === undefined) {
        if (!aValueToSort && !bValueToSort) return 0;

        if (!aValueToSort) return 1;

        if (!bValueToSort) return -1;

        if (!aValueToSort || !bValueToSort) return 0;
      }

      const res = compare(aValueToSort!, bValueToSort!);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(member: Member, term: string) {
  return (
    member.firstName.toLowerCase().includes(term.toLowerCase()) ||
    member.lastName.toLowerCase().includes(term.toLowerCase()) ||
    member.email.toLowerCase().includes(term.toLowerCase())
  );
}

@Injectable({ providedIn: 'any' })
export class MembersService {
  private members: Member[] = [];

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _members = new BehaviorSubject<Member[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
  };

  constructor(private httpClient: HttpClient) {
    this.updateMembers();

    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false)),
      )
      .subscribe((result) => {
        this._members.next(result.members);
        this._total$.next(result.total);
      });

    this._search$.next();
  }

  get members$() {
    return this._members.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } =
      this._state;

    // 1. sort
    let members = sort(this.members, sortColumn, sortDirection);

    // 2. filter
    members = members.filter((member) => matches(member, searchTerm));
    const total = members.length;

    // 3. paginate
    members = members.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize,
    );
    return of({ members: members, total });
  }

  updateMembers() {
    this.getMembersFromBackEnd().subscribe((value) => {
      this.members = value.payload.map<Member>((pl) => {
        return {
          memberNumber: pl.memberNumber || 0,
          firstName: pl.firstName,
          lastName: pl.lastName,
          email: pl.email,
          id: pl.id!,
          phoneNumber: pl.phoneNumber,
          birthDate: new Date(pl.birthDate!),
          addedDate: pl.addedDate ? new Date(pl.addedDate) : undefined,
          streetAndNumber: pl.streetAndNumber,
          postalCode: pl.postalCode,
          city: pl.city,
          country: pl.country,
          hasActiveCard: pl.hasActiveCard ? pl.hasActiveCard : false,
        };
      });
      this._search$.next();
    });
  }

  private getMembersFromBackEnd(): Observable<{
    message: string;
    payload: MemberPayload[];
  }> {
    return this.httpClient.get<{ message: string; payload: MemberPayload[] }>(
      `${environment.apiUrl}/member`,
      { headers: getAuthorizationHeaders() },
    );
  }

  public addMember(member: MemberPayload): Observable<{
    message: string;
    status: string;
  }> {
    return this.httpClient.post<{
      status: string;
      message: string;
      payload: MemberPayload[];
    }>(`${environment.apiUrl}/member`, member, {
      headers: getAuthorizationHeaders(),
    });
  }

  public editMember(member: MemberPayload): Observable<{
    message: string;
    status: string;
  }> {
    return this.httpClient.put<{
      status: string;
      message: string;
      payload: MemberPayload[];
    }>(`${environment.apiUrl}/member`, member, {
      headers: getAuthorizationHeaders(),
    });
  }

  public deleteMember(id: string): Observable<{
    message: string;
    status: string;
  }> {
    return this.httpClient.delete<{
      status: string;
      message: string;
    }>(`${environment.apiUrl}/member/${id}`, {
      headers: getAuthorizationHeaders(),
    });
  }
}
