<form>
  <div class="mb-3 row">
    <label
      for="table-complete-search"
      class="col-xs-3 col-sm-auto col-form-label"
      >Szukaj:</label
    >
    <div class="col-xs-3 col-sm-auto">
      <input
        id="table-complete-search"
        type="text"
        class="form-control"
        name="searchTerm"
        [(ngModel)]="service.searchTerm"
      />
    </div>
    @if (service.loading$ | async) {
    <span class="col col-form-label">Loading...</span>
    }
  </div>

  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col" sortable="firstname" (sort)="onSort($event)">
          Imię
          <span [hidden]="sortColumn!=='firstname'"
            ><span [hidden]="sortDir!=='asc'">(&uarr;)</span
            ><span [hidden]="sortDir!=='desc'">(&darr;)</span></span
          >
        </th>
        <th scope="col" sortable="lastName" (sort)="onSort($event)">
          Nazwisko
          <span [hidden]="sortColumn!=='lastName'"
            ><span [hidden]="sortDir!=='asc'">(&uarr;)</span
            ><span [hidden]="sortDir!=='desc'">(&darr;)</span></span
          >
        </th>
        <th scope="col" sortable="username" (sort)="onSort($event)">
          Nazwa Użytkownika
          <span [hidden]="sortColumn!=='username'"
            ><span [hidden]="sortDir!=='asc'">(&uarr;)</span
            ><span [hidden]="sortDir!=='desc'">(&darr;)</span></span
          >
        </th>
        <th scope="col" sortable="email" (sort)="onSort($event)">
          Email
          <span [hidden]="sortColumn!=='email'"
            ><span [hidden]="sortDir!=='asc'">(&uarr;)</span
            ><span [hidden]="sortDir!=='desc'">(&darr;)</span></span
          >
        </th>
      </tr>
    </thead>
    <tbody>
      @for (admin of admins$ | async; track admin.id) {
      <tr>
        <td>
          <ngb-highlight
            [result]="admin.firstname"
            [term]="service.searchTerm"
          />
        </td>
        <td>
          <ngb-highlight
            [result]="admin.lastName"
            [term]="service.searchTerm"
          />
        </td>
        <td>
          <ngb-highlight
            [result]="admin.username"
            [term]="service.searchTerm"
          />
        </td>
        <td>
          <ngb-highlight [result]="admin.email" [term]="service.searchTerm" />
        </td>
        <td>
          <button (click)="trigerResetPassword(admin)">Zresetuj hasło</button>
          <button (click)="deleteAdmin(admin)">Usuń admina</button>
        </td>
      </tr>
      } @empty {
      <tr>
        <td colspan="4" style="text-align: center">No admins found</td>
      </tr>
      }
    </tbody>
  </table>

  <div class="d-flex justify-content-between p-2">
    <ngb-pagination
      [collectionSize]="(total$ | async)!"
      [(page)]="service.page"
      [pageSize]="service.pageSize"
    >
    </ngb-pagination>

    <select
      class="form-select"
      style="width: auto"
      name="pageSize"
      [(ngModel)]="service.pageSize"
    >
      <option [ngValue]="5">5 wierszy na strone</option>
      <option [ngValue]="10">10 wierszy na strone</option>
      <option [ngValue]="15">15 wierszy na strone</option>
    </select>
  </div>
</form>
