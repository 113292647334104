/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { Injectable } from '@angular/core';
import { Card } from '../model/card';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../environments/environment';
import { getAuthorizationHeaders } from '../utils/utils';

@Injectable({ providedIn: 'any' })
export class CardsService {
  constructor(private httpClient: HttpClient) {}

  public getCards(
    memberId: string,
  ): Observable<{ message: string; payload: Card[] }> {
    return this.httpClient.get<{ message: string; payload: Card[] }>(
      `${environment.apiUrl}/card?memberId=${memberId}`,
      { headers: getAuthorizationHeaders() },
    );
  }

  public addCard(
    memberId: string,
    year: number,
  ): Observable<{
    message: string;
    status: string;
  }> {
    return this.httpClient.post<{
      status: string;
      message: string;
    }>(
      `${environment.apiUrl}/card`,
      { memberId, year },
      { headers: getAuthorizationHeaders() },
    );
  }

  public deleteCard(id: string): Observable<{
    message: string;
    status: string;
  }> {
    console.log(`Delete card ${id}`);
    return this.httpClient.delete<{
      status: string;
      message: string;
    }>(`${environment.apiUrl}/card`, {
      body: { id },
      headers: getAuthorizationHeaders(),
    });
  }

  public activateCard(id: string): Observable<{
    message: string;
    status: string;
  }> {
    console.log(`Activate card ${id}`);
    return this.httpClient.post<{
      status: string;
      message: string;
    }>(
      `${environment.apiUrl}/card/activate`,
      { id },
      { headers: getAuthorizationHeaders() },
    );
  }

  public deactivateCard(id: string): Observable<{
    message: string;
    status: string;
  }> {
    console.log(`Deactivate card ${id}`);
    return this.httpClient.post<{
      status: string;
      message: string;
    }>(
      `${environment.apiUrl}/card/deactivate`,
      { id },
      { headers: getAuthorizationHeaders() },
    );
  }

  public downloadCard(id: string): Observable<Blob> {
    return this.httpClient.get(
      `${environment.apiUrl}/card/download?cardId=${id}`,
      {
        headers: getAuthorizationHeaders(),
        responseType: 'blob',
      },
    );
  }
}
