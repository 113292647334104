import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { RoutesPaths } from '../../app/app-routing.module';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-login-page',
  standalone: true,
  templateUrl: './loginPage.component.html',
  styleUrl: './loginPage.component.css',
  imports: [CommonModule],
})
export class LoginPageComponent implements OnInit {
  @Output() loggedIn = new EventEmitter();
  qrMode = false;
  wrongPassword: boolean = false;
  authService: AuthService = inject(AuthService);
  jwt: string = '';

  constructor(
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {}

  ngOnInit(): void {
    const token = window.localStorage.getItem('token');
    if (token)
      this.authService.validateToken(token).subscribe({
        next: () => {
          this.router.navigate(['/', RoutesPaths.members]);
        },
        error: () => {
          window.localStorage.removeItem('token');
        },
      });
  }

  changeButtonMode(btn: HTMLElement, enabled: boolean) {
    btn.setAttribute('disabled', enabled ? 'false' : 'true');
    if (!enabled) btn.setAttribute('style', 'color:grey; border-color:grey;');
    else {
      btn.removeAttribute('style');
      btn.removeAttribute('disabled');
    }
  }

  onSubmit(username: string, password: string, submitBtn: HTMLElement) {
    this.changeButtonMode(submitBtn, false);
    this.wrongPassword = false;

    const recaptchaSub = this.recaptchaV3Service
      .execute('login')
      .subscribe((token) => {
        const innersub = this.authService
          .login(username, password, token)
          .subscribe({
            next: (data) => {
              this.wrongPassword = data.jwt != null;
              this.jwt = data.jwt;
              localStorage.setItem('token', this.jwt);
              this.loggedIn.emit();
              this.changeButtonMode(submitBtn, true);
              innersub.unsubscribe();
              this.router.navigate(['/', RoutesPaths.members]);
            },
            error: (e) => {
              if (e.error.error !== 'WRONG_PASSWORD') {
                window.alert(
                  `Wystapił problem: ${e.error.error} ${e.error.message}`,
                );
                this.changeButtonMode(submitBtn, true);
                return;
              }
              console.log(e);
              this.wrongPassword = true;
              this.changeButtonMode(submitBtn, true);
              innersub.unsubscribe();
            },
          });

        recaptchaSub.unsubscribe();
      });
  }

  keyInPasswordPage(key: string, submitButton: HTMLElement) {
    if (key === 'Enter') {
      submitButton.click();
    }
  }
}
