import { Component } from '@angular/core';
import { EventType, Router } from '@angular/router';
import { RoutesPaths } from '../../app/app-routing.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css',
})
export class NavbarComponent {
  active: keyof typeof RoutesPaths | '' = '';
  admins = RoutesPaths.admins;
  members = RoutesPaths.members;
  constructor(public router: Router) {
    this.checkWhichPageIsActive();

    this.router.events.subscribe({
      next: (event) => {
        if (event.type === EventType.NavigationEnd) {
          this.checkWhichPageIsActive();
        }
      },
    });
  }

  private checkWhichPageIsActive() {
    const nav = this.router.lastSuccessfulNavigation;

    if (nav) {
      let route = null;
      for (let key in RoutesPaths) {
        route = nav.extractedUrl
          .toString()
          .endsWith(RoutesPaths[key as keyof typeof RoutesPaths])
          ? key
          : route;
      }

      this.active = (route as keyof typeof RoutesPaths) || '';
    }
  }

  linkClick(link: RoutesPaths) {
    this.router.navigate(['/', link]);
  }

  logout() {
    window.localStorage.removeItem('token');
    this.router.navigate(['/', RoutesPaths.login]);
  }
}
