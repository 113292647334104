import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { validateCardService } from '../../services/validateCard.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-validate-card-page',
  standalone: true,
  templateUrl: './validateCard.component.html',
  styleUrl: './validateCard.component.css',
  imports: [CommonModule],
})
export class ValidateCardComponent implements OnInit {
  validateCardService: validateCardService = inject(validateCardService);
  cardIsActive = true;
  firstName = '';
  lastName = '';
  birthDate = '';
  cardId = '';
  loading = true;
  currentYear = new Date().getFullYear();

  constructor(private route: ActivatedRoute) {}
  ngOnInit(): void {
    this.cardId = this.route.snapshot.paramMap.get('cardId') || '';
    this.validateCardService.validateCard(this.cardId).subscribe({
      next: (value) => {
        if (value.payload) {
          this.cardIsActive =
            value.payload.isActive && this.currentYear === value.payload.year;
          this.firstName = value.payload.firstName;
          this.lastName = value.payload.lastName;
          this.birthDate = value.payload.birthDate
            ? new Date(value.payload.birthDate).toLocaleDateString()
            : '';
          this.loading = false;
        } else {
          this.cardIsActive = false;
          this.loading = false;
        }
      },
    });
  }
}
