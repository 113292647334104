<main>
  <section class="content">
    <h2>Administratorzy</h2>
    <button (click)="addAdminForm.openForm()">Dodaj Admina</button>
  </section>
  <ngbd-adminForm
    #addAdminForm
    (adminsUpdated)="adminsTable.updateTable()"
  ></ngbd-adminForm>
  <ngbd-admins-table #adminsTable></ngbd-admins-table>
</main>
`,
