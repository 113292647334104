export class ButtonAnimation {
  private initialText: string | null;
  private animation?: number;
  constructor(private element: Element) {
    this.initialText = element.textContent;
  }

  startAnimation() {
    let tmpText: string = '';
    for (let i = 0; i < this.initialText!.length; i++) {
      tmpText += '.';
    }
    this.element.textContent = tmpText;
    this.element.setAttribute('disabled', 'true');
    let j = 0;
    this.animation = window.setInterval(() => {
      const index = j++ % tmpText.length;
      const toSet =
        tmpText.substring(0, index) + '|' + tmpText.substring(index + 1);
      toSet;
      this.element.textContent = toSet;
    }, 100);
  }

  stopAnimation() {
    this.element.removeAttribute('disabled');
    this.element.textContent = this.initialText;
    window.clearInterval(this.animation!);
  }
}
