import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordService } from '../../services/password.service';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RoutesPaths } from '../../app/app-routing.module';

@Component({
  selector: 'app-login-page',
  standalone: true,
  templateUrl: './resetPasswordPage.component.html',
  styleUrl: './resetPasswordPage.component.css',
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class ResetPasswordComponent implements OnInit {
  @Output() loggedIn = new EventEmitter();
  qrMode = false;
  wrongPassword: boolean = false;
  differentPasswords: boolean = false;
  linkExpired: boolean = false;

  resetPasswordService: PasswordService = inject(PasswordService);

  resetPasswordForm = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    repeatPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.resetPasswordService
      .checkLinkValidity(this.route.snapshot.paramMap.get('token') || '')
      .subscribe({
        next: (value) => {
          this.linkExpired = value.status !== 'OK';
        },
        error: () => {
          this.linkExpired = true;
        },
      });
  }

  changeButtonMode(btn: HTMLElement, enabled: boolean) {
    btn.setAttribute('disabled', enabled ? 'false' : 'true');
    if (!enabled) btn.setAttribute('style', 'color:grey; border-color:grey;');
    else {
      btn.removeAttribute('style');
      btn.removeAttribute('disabled');
    }
  }

  onSubmit(submitBtn: HTMLElement) {
    this.wrongPassword = this.resetPasswordForm.controls.password.invalid;
    if (!this.resetPasswordForm.valid) return;

    this.differentPasswords =
      this.resetPasswordForm.value.password !==
      this.resetPasswordForm.value.repeatPassword;

    if (this.differentPasswords) return;

    this.changeButtonMode(submitBtn, false);
    this.wrongPassword = false;
    const token = this.route.snapshot.paramMap.get('token') || '';

    console.log(`token equals =${token}`);

    const innersub = this.resetPasswordService
      .resetPassword(
        token,
        this.resetPasswordForm.value.password!,
        this.resetPasswordForm.value.repeatPassword!,
      )
      .subscribe({
        next: (data) => {
          if (data.status !== 'OK') {
            window.alert(data.message || 'Coś poszło nie tak');
          }
          if (data.status === 'OK') {
            window.alert('Hasło ustawione, Zaloguj się');
            this.router.navigate(['/', RoutesPaths.login]);
          }
          this.changeButtonMode(submitBtn, true);
          innersub.unsubscribe();
        },
        error: (e) => {
          window.alert(`${JSON.stringify(e)}`);
          this.changeButtonMode(submitBtn, true);
          innersub.unsubscribe();
        },
      });
  }

  keyInPasswordPage(key: string, submitButton: HTMLElement) {
    if (key === 'Enter') {
      submitButton.click();
    }
  }
}
