import { CommonModule, DecimalPipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Output,
  TemplateRef,
  viewChild,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  ModalDismissReasons,
  NgbDate,
  NgbDatepickerModule,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';

import { MembersService } from '../../services/members.service';
import { AdminsService } from '../../services/admins.service';
import { Admin, AdminPayload } from '../../model/admin';

enum mode {
  add,
  edit,
}

@Component({
  selector: 'ngbd-adminForm',
  standalone: true,
  imports: [
    NgbDatepickerModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DecimalPipe,
  ],
  templateUrl: './adminForm.html',
  styleUrls: ['./adminForm.css'],
  providers: [MembersService, DecimalPipe],
})
export class AdminForm {
  @Output() adminsUpdated = new EventEmitter();

  private modalService = inject(NgbModal);
  private mode = mode.add;
  title = 'Dodaj Admina';
  private modal: NgbModalRef | null = null;

  addAdminForm = new FormGroup({
    id: new FormControl(),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    username: new FormControl('', [Validators.required]),
  });

  showValidationMessages = {
    firstName: false,
    lastName: false,
    email: false,
    username: false,
  };

  @ViewChild('openFormButton') openFormButton!: ElementRef;

  closeResult = '';

  constructor(public service: AdminsService) {}

  public openForm() {
    this.title = 'Dodaj Admina';
    this.mode = mode.add;
    this.openFormButton.nativeElement.click();
  }

  public openFormEdit(admin: Admin) {
    this.title = 'Edytuj Admina';
    this.mode = mode.edit;
    console.log(`Email is ${admin.email}`);

    this.openFormButton.nativeElement.click();

    this.addAdminForm.setValue({
      id: admin.id,
      firstName: admin.firstname,
      lastName: admin.lastName,
      email: admin.email,
      username: admin.username,
    });
  }

  public open(content: TemplateRef<any>) {
    this.addAdminForm.setValue({
      id: null,
      firstName: null,
      lastName: null,
      email: null,
      username: null,
    });

    this.modal = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });

    this.modal.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  submit() {
    this.showValidationMessages.firstName =
      !this.addAdminForm.controls.firstName.valid;
    this.showValidationMessages.lastName =
      !this.addAdminForm.controls.lastName.valid;
    this.showValidationMessages.email = !this.addAdminForm.controls.email.valid;
    this.showValidationMessages.username =
      !this.addAdminForm.controls.username.valid;

    if (!this.addAdminForm.valid) return;

    if (this.mode === mode.add) {
      const admin = this.readAdminForm();

      this.service.addAdmin(admin).subscribe((value) => {
        if (value.status === 'OK') {
          window.alert(`Admin został dodany`);
          this.adminsUpdated.emit();
          this.modal?.close();
        } else window.alert(`Admin create failed: ${value.message}`);
      });
    }

    if (this.mode === mode.edit) {
      const admin = this.readAdminForm();

      this.service.editAdmin(admin).subscribe((value) => {
        if (value.status === 'OK') {
          this.adminsUpdated.emit();
          window.alert(`Admin został zaktualizowany`);
          this.modal?.close();
        } else window.alert(`Member update failed: ${value.message}`);
      });
    }
  }

  private readAdminForm(): AdminPayload {
    const admin: AdminPayload = {
      id: this.addAdminForm.value.id ?? '',
      firstname: this.addAdminForm.value.firstName!,
      lastName: this.addAdminForm.value.lastName!,
      email: this.addAdminForm.value.email!,
      username: this.addAdminForm.value.username!,
    };
    return admin;
  }

  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }
}
