import { Component, inject, OnChanges } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { EventType, Router, RouterEvent } from '@angular/router';
import { LoggedInStateValidator } from '../utils/loggedInStateValidator';

@Component({
  selector: 'app-root',
  template: `
    <h1>{{ title }}</h1>
    <app-navbar *ngIf="loggedInStateValidator.isLoggedIn"></app-navbar>
    <router-outlet></router-outlet>
  `,
  styleUrls: ['./app.css'],
})
export class AppComponent {
  authService: AuthService = inject(AuthService);
  loggedInStateValidator: LoggedInStateValidator;
  title = 'BSP - Legitymacje';
  loggedIn = false;

  constructor(private router: Router) {
    this.loggedInStateValidator = LoggedInStateValidator.getInstance(
      this.authService,
    );
    this.loggedInStateValidator.getLoggedInStateNow();

    this.router.events.subscribe({
      next: (event) => {
        if (event.type === EventType.NavigationEnd) {
          this.loggedInStateValidator.getLoggedInStateNow();
        }
      },
    });
  }
}
