<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="addMemberForm">
      <input
        id="id"
        formControlName="id"
        class="form-control"
        placeholder=""
        name="dp"
        hidden
      />
      <div class="mb-3">
        <label for="firstName">Imię</label>
        <div class="input-group">
          <input
            id="firstName"
            formControlName="firstName"
            class="form-control"
            placeholder="Imię"
            name="dp"
          />
        </div>
        <div
          id="firstNameValError"
          [hidden]="!showValidationMessages.firstName"
          class="validationMessage"
        >
          To pole jest wymagane!
        </div>
      </div>
      <div class="mb-3">
        <label for="lastName">Nazwisko</label>
        <div class="input-group">
          <input
            id="lastName"
            formControlName="lastName"
            class="form-control"
            placeholder="Nazwisko"
            name="dp"
          />
        </div>
        <div
          [hidden]="!showValidationMessages.lastName"
          class="validationMessage"
        >
          To pole jest wymagane!
        </div>
      </div>
      <div class="mb-3">
        <label for="email">Email</label>
        <div class="input-group">
          <input
            id="email"
            formControlName="email"
            class="form-control"
            placeholder="email"
            name="dp"
            email
          />
        </div>
        <div [hidden]="!showValidationMessages.email" class="validationMessage">
          Poprawny email jest wymagany!
        </div>
      </div>
      <div class="mb-3">
        <label for="phone">Telefon</label>
        <div class="input-group">
          <input
            id="phone"
            formControlName="phone"
            class="form-control"
            placeholder="Telefon"
            name="dp"
          />
        </div>
        <div class="validationMessage" [hidden]="!showValidationMessages.phone">
          To pole jest wymagane!
        </div>
      </div>
      <div class="mb-3">
        <label for="dateOfBirth">Data urodzenia</label>
        <div class="input-group">
          <input
            id="dateOfBirth"
            formControlName="dateOfBirth"
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="dp"
            ngbDatepicker
            [startDate]="defaultDateOfBirth"
            #dp="ngbDatepicker"
          />
          <button
            class="btn btn-outline-secondary bi bi-calendar3"
            (click)="dp.toggle()"
            type="button"
          ></button>
        </div>
        <div
          class="validationMessage"
          [hidden]="!showValidationMessages.dateOfBirth"
        >
          To pole jest wymagane!
        </div>
      </div>
      <div class="mb-3">
        <label for="addedDate">Data dodania</label>
        <div class="input-group">
          <input
            id="addedDate"
            formControlName="addedDate"
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="addedDate"
            ngbDatepicker
            #addedDate="ngbDatepicker"
          />
          <button
            class="btn btn-outline-secondary bi bi-calendar3"
            (click)="addedDate.toggle()"
            type="button"
          ></button>
        </div>
      </div>
      <div class="mb-3">
        <label for="phone">Ulica i nr</label>
        <div class="input-group">
          <input
            id="streetAndNumber"
            formControlName="streetAndNumber"
            class="form-control"
            placeholder="Ulica i nr"
            name="dp"
          />
        </div>
        <div
          class="validationMessage"
          [hidden]="!showValidationMessages.streetAndNumber"
        >
          To pole jest wymagane!
        </div>
      </div>
      <div class="mb-3">
        <label for="phone">Kod Pocztowy i miasto</label>
        <div class="input-group">
          <input
            id="postalCode"
            formControlName="postalCode"
            class="form-control"
            placeholder="00-000"
            name="dp"
          />
          <input
            id="city"
            formControlName="city"
            class="form-control"
            placeholder="Miasto"
            name="dp"
          />
        </div>
        <div
          class="validationMessage"
          [hidden]="!showValidationMessages.postalCodeAndCity"
        >
          To pole jest wymagane!
        </div>
      </div>
      <div class="mb-3">
        <label for="phone">Kraj</label>
        <div class="input-group">
          <input
            id="country"
            formControlName="country"
            class="form-control"
            placeholder="Polska"
            name="dp"
          />
        </div>
        <div
          class="validationMessage"
          [hidden]="!showValidationMessages.country"
        >
          To pole jest wymagane!
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="submit();">
      Zapisz
    </button>
  </div>
</ng-template>

<button #openFormButton hidden (click)="open(content)">Dodaj</button>
