import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MembersPageComponent } from '../components/membersPage/membersPage.component';
import { LoginPageComponent } from '../components/loginPage/loginPage.component';
import { adminsPageComponent } from '../components/adminsPage/adminsPage.component';
import { ValidateCardComponent } from '../components/validateCard/validateCard.component';
import { ResetPasswordComponent } from '../components/resetPasswordPage/resetPasswordPage.component';

export enum RoutesPaths {
  admins = 'admins',
  members = 'members',
  cardValidator = 'cardValidator',
  resetPassword = 'resetPassword',
  login = 'login',
}

const routes: Routes = [
  { path: RoutesPaths.admins, component: adminsPageComponent },
  { path: RoutesPaths.members, component: MembersPageComponent },
  {
    path: `${RoutesPaths.cardValidator}/:cardId`,
    component: ValidateCardComponent,
  },
  {
    path: `${RoutesPaths.resetPassword}/:token`,
    component: ResetPasswordComponent,
  },
  { path: RoutesPaths.login, component: LoginPageComponent },
  { path: '', component: LoginPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
