<div class="wrapper">
  <div class="navbar">
    <button
      (click)="linkClick(admins)"
      class="{{ active === 'admins' ? 'active' : 'inactive' }}"
    >
      Administratorzy
    </button>
    <button
      (click)="linkClick(members)"
      class="{{ active === 'members' ? 'active' : 'inactive' }}"
    >
      Członkowie
    </button>
    <button (click)="logout()">Wyloguj</button>
  </div>
</div>
