<form>
  <div class="mb-3 row">
    <label
      for="table-complete-search"
      class="col-xs-3 col-sm-auto col-form-label"
      >Szukaj:</label
    >
    <div class="col-xs-3 col-sm-auto">
      <input
        id="table-complete-search"
        type="text"
        class="form-control"
        name="searchTerm"
        [(ngModel)]="service.searchTerm"
      />
    </div>
    @if (service.loading$ | async) {
    <span class="col col-form-label">Loading...</span>
    }
  </div>

  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col" sortable="memberNumber" (sort)="onSort($event)">
          #
          <span [hidden]="sortColumn!=='memberNumber'"
            ><span [hidden]="sortDir!=='asc'">(&uarr;)</span
            ><span [hidden]="sortDir!=='desc'">(&darr;)</span></span
          >
        </th>
        <th scope="col" sortable="firstName" (sort)="onSort($event)">
          Imię
          <span [hidden]="sortColumn!=='firstName'"
            ><span [hidden]="sortDir!=='asc'">(&uarr;)</span
            ><span [hidden]="sortDir!=='desc'">(&darr;)</span></span
          >
        </th>
        <th scope="col" sortable="lastName" (sort)="onSort($event)">
          Nazwisko
          <span [hidden]="sortColumn!=='lastName'"
            ><span [hidden]="sortDir!=='asc'">(&uarr;)</span
            ><span [hidden]="sortDir!=='desc'">(&darr;)</span></span
          >
        </th>
        <th scope="col" sortable="email" (sort)="onSort($event)">
          Email
          <span [hidden]="sortColumn!=='email'"
            ><span [hidden]="sortDir!=='asc'">(&uarr;)</span
            ><span [hidden]="sortDir!=='desc'">(&darr;)</span></span
          >
        </th>
        <th scope="col" sortable="phoneNumber" (sort)="onSort($event)">
          Telefon
          <span [hidden]="sortColumn!=='phoneNumber'"
            ><span [hidden]="sortDir!=='asc'">(&uarr;)</span
            ><span [hidden]="sortDir!=='desc'">(&darr;)</span></span
          >
        </th>
        <th scope="col" sortable="birthDate" (sort)="onSort($event)">
          Data Urodzin
          <span [hidden]="sortColumn!=='birthDate'"
            ><span [hidden]="sortDir!=='asc'">(&uarr;)</span
            ><span [hidden]="sortDir!=='desc'">(&darr;)</span></span
          >
        </th>
        <th scope="col" sortable="addedDate" (sort)="onSort($event)">
          Data Dodania
          <span [hidden]="sortColumn!=='addedDate'"
            ><span [hidden]="sortDir!=='asc'">(&uarr;)</span
            ><span [hidden]="sortDir!=='desc'">(&darr;)</span></span
          >
        </th>
        <th scope="col">Adres</th>
        <th scope="col" sortable="hasActiveCard" (sort)="onSort($event)">
          Ma ważną Legitymację
          <span [hidden]="sortColumn!=='hasActiveCard'"
            ><span [hidden]="sortDir!=='asc'">(&uarr;)</span
            ><span [hidden]="sortDir!=='desc'">(&darr;)</span></span
          >
        </th>
      </tr>
    </thead>
    <tbody>
      @for (member of members$ | async; track member.id) {
      <tr>
        <td>
          <ngb-highlight
            [result]="member.memberNumber.toString(10)"
            [term]="service.searchTerm"
          />
        </td>
        <td>
          <ngb-highlight
            [result]="member.firstName"
            [term]="service.searchTerm"
          />
        </td>
        <td>
          <ngb-highlight
            [result]="member.lastName"
            [term]="service.searchTerm"
          />
        </td>
        <td>
          <ngb-highlight [result]="member.email" [term]="service.searchTerm" />
        </td>
        <td>
          <ngb-highlight
            [result]="member.phoneNumber"
            [term]="service.searchTerm"
          />
        </td>
        <td>
          <ngb-highlight
            [result]="member.birthDate.toLocaleDateString() || ''"
            [term]="service.searchTerm"
          />
        </td>
        <td>
          <ngb-highlight
            [result]="member.addedDate?.toLocaleDateString() || ''"
            [term]="service.searchTerm"
          />
        </td>
        <td>
          <ngb-highlight
            [result]="transformAdress(member)"
            [term]="service.searchTerm"
          />
        </td>
        <td>
          <ngb-highlight
            [result]="member.hasActiveCard ? 'Aktywny' : 'Nieaktywny'"
            [term]="service.searchTerm"
          />
        </td>
        <td>
          <button (click)="onEditClick(member)">Edytuj</button>
          <button (click)="onCardsClick(member)">Legitymacje</button>
          <button (click)="deleteMember(member)">Usuń</button>
        </td>
      </tr>
      } @empty {
      <tr>
        <td colspan="4" style="text-align: center">Nie znaleziono członków</td>
      </tr>
      }
    </tbody>
  </table>

  <div class="d-flex justify-content-between p-2">
    <ngb-pagination
      [collectionSize]="(total$ | async)!"
      [(page)]="service.page"
      [pageSize]="service.pageSize"
    >
    </ngb-pagination>

    <select
      class="form-select"
      style="width: auto"
      name="pageSize"
      [(ngModel)]="service.pageSize"
    >
      <option [ngValue]="5">5 wierszy na strone</option>
      <option [ngValue]="10">10 wierszy na strone</option>
      <option [ngValue]="15">15 wierszy na strone</option>
    </select>
  </div>
</form>
